


















































import { Component, Vue, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';

import { AutoSaveCollectionModel } from '@/core/webapi';
import { AutoSaveService } from '@/core/services';
import { AutoSaveTypes } from '@/core/constants';

@Component
export default class ExistingDraftsWarningCpt extends AppVue {
  showWarning = false;
  showDialog = false;
  drafts: AutoSaveCollectionModel[] = [];

  async created() {
    if (!this.isReporter) {
      return;
    }

    this.drafts = await AutoSaveService.getAllDrafts();
    this.showWarning = this.drafts.length > 0;
  }

  async showDrafts() {
    if (this.showWarning) {
      this.showDialog = true;
    }
  }

  getTypeCaption(draft: AutoSaveCollectionModel) {
    return _.startCase(draft.autoSaveType);
  }

  getUrl(draft: AutoSaveCollectionModel) {
    switch (draft.autoSaveTypeId) {
      case AutoSaveTypes.InjuryReport:
        return !!draft.forId ? `/injuries/${draft.forId}/edit` : `/injuries/new/${draft.id}/draft`;
      case AutoSaveTypes.IllnessReport:
        return !!draft.forId ? `/illnesses/${draft.forId}/edit` : `/illnesses/new/${draft.id}/draft`;
      case AutoSaveTypes.ExposureReport:
        return !!draft.forId ? `/exposures/${draft.forId}/edit` : `/exposures/new/${draft.id}/draft`;
      case AutoSaveTypes.CommunityInjuryReport:
        return !!draft.forId ? `/c/injuries/${draft.forId}/edit` : `/c/injuries/new/${draft.id}/draft`;
      case AutoSaveTypes.CatastrophicInjuryReport:
        return !!draft.forId
          ? `/catastrophic-injuries/${draft.forId}/edit`
          : `/catastrophic-injuries/new/${draft.id}/draft`;
      case AutoSaveTypes.CatastrophicFollowUp:
        return `/catastrophic-injuries/${draft.forId}/follow-up`;
      default:
        return '/';
    }
  }
}
